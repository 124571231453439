import { useState, useRef } from "react";
import {
  Flex,
  Text,
  Grid,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  useColorModeValue,
  Image,
  useToast,
} from "@chakra-ui/react";
import InputField from "components/Forms/InputField";

import { uuidv4 } from "@firebase/util";
import * as firebaseLib from "utils/FirebaseUtils";
import { useHistory } from "react-router";
export default function AddImage() {
  const toast = useToast();
  const navigate = useHistory();
  const formRef = useRef(null);
  const [image, setImage] = useState("");
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const onChange = (e) => {
    const file = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      setImageUrl(reader.result);
      // Create an Image object
      const img = new window.Image();
      img.onload = () => {
        // Get height and width of the image
        setHeight(img.height);
        setWidth(img.width);
      };
      // Set src after onload handler is defined
      img.src = reader.result;
    };

    reader.readAsDataURL(file);
    setImage(file);
    setDisabled(false);
  };

  const cardColor = useColorModeValue("white", "gray.700");
  const handleSubmit = (e) => {
    e.preventDefault();
    const uid = uuidv4();
    firebaseLib
      .sendDataRealtime("Gallery", uid, {
        id: uid,
        height: height,
        width: width,
        link: image,
      })
      .then(() => {
        toast({
          position: "bottom-right",
          title: "Image Added",
          variant: "subtle",
          status: "success",
          duration: 4000,
          isClosable: true,
        });
        formRef.current.reset();
      });
  };
  return (
    <Flex flexDirection="column">
      <Grid
        templateColumns={{ md: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        gap="24px"
      >
        <Card
          variant="elevated"
          bg={cardColor}
          boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)"
          borderRadius="15px"
        >
          <CardBody>
            <form
              style={{ width: "100%" }}
              ref={formRef}
              onSubmit={(e) => handleSubmit(e)}
              id="c9ec00d7-cfee-4419-b72f-e7b69a26204e"
            >
              <Grid templateColumns="1fr" gap="1rem" width="100%">
                {imageUrl && (
                  <Image
                    className="rounded"
                    maxW="100%"
                    maxH="20rem"
                    borderRadius="16px"
                    src={imageUrl}
                    alt="Preview"
                  />
                )}
                <Flex direction="column" gap="1rem">
                  <Button as="label">
                    Choose Photo
                    <input
                      required
                      hidden
                      type="file"
                      onChange={onChange}
                      accept="image/png, image/jpeg"
                      id="account-settings-upload-image"
                    />
                  </Button>
                  <Button
                    isLoading={isLoading}
                    isDisabled={disabled}
                    colorScheme="teal"
                    onClick={() => {
                      setIsLoading(true);
                      firebaseLib.uploadImage(
                        image,
                        setImage,
                        setDisabled,
                        setIsLoading
                      ).then(() => {
                        toast({
                          position: "bottom-right",
                          title: "Image Uploaded",
                          variant: "subtle",
                          status: "success",
                          duration: 4000,
                          isClosable: true,
                        });
                      });
                    }}
                  >
                    Upload
                  </Button>
                </Flex>
              </Grid>
              {/* <Grid templateColumns="1fr 1fr" gap="1rem" width="100%" mt="1rem">
                <InputField
                  type="number"
                  label="Height"
                  placeholder=""
                  state={height}
                  onChange={setHeight}
                  required
                />
                <InputField
                  type="number"
                  label="Width"
                  placeholder=""
                  state={width}
                  onChange={setWidth}
                  required
                />
              </Grid> */}
            </form>
          </CardBody>
          <CardFooter>
            <Button
              bg="teal.400"
              type="submit"
              form="c9ec00d7-cfee-4419-b72f-e7b69a26204e"
            >
              Add
            </Button>
          </CardFooter>
        </Card>
      </Grid>
    </Flex>
  );
}
