import { Flex, Grid, Progress, Button } from "@chakra-ui/react";
import { useState, useEffect } from "react";

import ImageCard from "components/Card/ImageCard";
import { MdAdd } from "react-icons/md";

import { useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  getDataRealtime,
  getDataRealtimeInArray,
  getCollectionRealtimeRealtime,
} from "utils/FirebaseUtils";
import { useHistory } from "react-router";

export default function Photos() {
  const navigate = useHistory();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getDataRealtime(
      "Gallery",
      "",
      (e) => {
        setData(Object.values(e));
      },
      setLoading
    );
  }, []);

  return (
    <Flex flexDirection="column">
      {loading ? (
        <Progress colorScheme="teal" size="xs" isIndeterminate />
      ) : (
        <Grid
          templateColumns={{
            xl: "repeat(4,1fr)",
            md: "1fr 1fr 1fr",
            sm: "1fr",
          }}
          templateRows={{ md: "1fr auto", lg: "1fr" }}
          gap="24px"
        >
          {data
            .sort((a, b) => a.pos - b.pos) // Sort data array based on pos
            .map((node, i) => (
              <ImageCard key={i} data={node} path={"Gallery/" + node.id} />
            ))}
          <Button
            height="100%"
            width="100%"
            py="2.5rem"
            variant="ghost"
            colorScheme="teal"
            onClick={() => navigate.push("/admin/addimage")}
          >
            <MdAdd size="3rem" />
            <p></p>
          </Button>
        </Grid>
      )}
    </Flex>
  );
}
