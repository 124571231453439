import ReusableSlider from "./ReuseableSlider";
import {
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { MdDone } from "react-icons/md";
import { useSelector } from "react-redux";
import { getDataRealtimeInArray } from "utils/FirebaseUtils";

function InputField({
  label,
  type,
  value,
  state = "",
  disabled = false,
  size = "md",
  placeholder = "Select option",
  helper = null,
  onChange = () => false,
  options = [],
  required = false,
  rightElement = null,
  borderRadius = "15px",
  isBody = true,
  offset = ".5rem",
  offsetLeft = "",
  button = null,
  focusBorderColor = "teal.400",
  links = [],
  stepper = false,
  ...rest
}) {
  const noLabel = ["switch", "editable"];
  const {
    isOpen: isOpenState,
    onOpen: onOpenState,
    onClose: onCloseState,
  } = useDisclosure();
  const user = useSelector((state) => state.user);
  const [funcs, setFuncs] = useState([]);
  const [link, setLink] = useState("");
  const [navType, setNavType] = useState("push");
  const inputType = () => {
    switch (type) {
      case null:
        return;
      case "slider":
        return (
          <ReusableSlider
            state={state}
            data={options}
            onChange={onChange}
            defaultValue={value}
          />
        );
      case "function":
        useEffect(() => {
          getDataRealtimeInArray(`Users/${user.uid}/Functions`, "", setFuncs);
        }, [user.uid]);
        return (
          <>
            <Tabs size="sm" colorScheme="teal">
              <TabList>
                <Tab>FUNCTION</Tab>
                <Tab>NAVIGATE</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Select
                    {...rest}
                    placeholder="Select a function"
                    required={required}
                    borderRadius={borderRadius}
                    focusBorderColor={focusBorderColor}
                    isDisabled={disabled}
                    defaultValue={value}
                    value={state}
                    onChange={(e) => onChange(e.target.value)}
                    // placeholder="Select option"
                  >
                    {funcs.map(({ name, args, func }, i) => {
                      return (
                        <option
                          key={i}
                          name={name}
                          value={
                            isBody
                              ? `{()=>${
                                  args ? "functionLib." + name : func
                                }(${args})}`
                              : `${
                                  args ? "functionLib." + name : func
                                }(${args})`
                          }
                        >
                          {args ? name : func}
                        </option>
                      );
                    })}
                  </Select>

                  <Button
                    w="100%"
                    onClick={onOpenState}
                    mt="1rem"
                    colorScheme="teal"
                  >
                    <p>Add Function</p>
                  </Button>
                  <Drawer
                    isOpen={isOpenState}
                    placement="right"
                    onClose={onCloseState}
                  >
                    <DrawerOverlay />
                    <DrawerContent overflowY="scroll">
                      <DrawerCloseButton color="red.400" />
                    </DrawerContent>
                  </Drawer>
                </TabPanel>
                <TabPanel>
                  <Flex direction="column" gap="1rem">
                    <InputField
                      type="select"
                      label="Nav Type"
                      placeholder="Select option"
                      value={navType}
                      state={navType}
                      onChange={setNavType}
                      options={["replace", "push"]}
                    />
                    {links.length !== 0 ? (
                      <Select
                        {...rest}
                        placeholder="Select a link"
                        required={required}
                        borderRadius={borderRadius}
                        focusBorderColor={focusBorderColor}
                        isDisabled={disabled}
                        defaultValue={value}
                        value={state}
                        onChange={(e) => onChange(e.target.value)}
                        // placeholder="Select option"
                      >
                        {links.map((link, i) => {
                          console.log(link);
                          return (
                            <option
                              key={i}
                              name={name}
                              value={
                                isBody
                                  ? `{()=>navigate.${navType}("/admin/${link}")}`
                                  : `navigate.${navType}("/admin/${link}")`
                              }
                            >
                              {link}
                            </option>
                          );
                        })}
                      </Select>
                    ) : (
                      <InputField
                        type="text"
                        label="Link"
                        value={link}
                        state={link}
                        onChange={setLink}
                        options={links}
                        placeholder="your-link"
                        rightElement={
                          <IconButton
                            color="teal.400"
                            variant="link"
                            onClick={() => {
                              onChange(
                                isBody
                                  ? `{()=>navigate.${navType}("/admin/${link}")}`
                                  : `navigate.${navType}("/admin/${link}")`,
                              );
                            }}
                            icon={<MdDone />}
                          />
                        }
                      />
                    )}
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </>
        );
      case "select":
        return (
          <Select
            {...rest}
            required={required}
            borderRadius={borderRadius}
            focusBorderColor={focusBorderColor}
            isDisabled={disabled}
            defaultValue={value}
            value={state}
            onChange={(e) => onChange(e.target.value)}
            placeholder="Select option"
          >
            {Array.isArray(options)
              ? options.map((type, i) => (
                  <option key={i} value={type}>
                    {Number.isInteger(type)
                      ? type
                      : type.charAt(0).toUpperCase() + type.slice(1)}
                  </option>
                ))
              : Object.keys(options).map((type, i) => (
                  <option key={i} name={type} value={options[type]}>
                    {type}
                  </option>
                ))}
          </Select>
        );
      case "switch":
        return (
          <Switch
            {...rest}
            required={required}
            colorScheme="teal"
            isDisabled={disabled}
            defaultChecked={value}
            value={state}
            size="sm"
            onChange={() => onChange(!value)}
          >
            {label}
          </Switch>
        );
      case "textarea":
        return (
          <InputGroup>
            <Textarea
              focusBorderColor={focusBorderColor}
              borderRadius={borderRadius}
              type={type}
              placeholder={placeholder}
              defaultValue={value}
              onChange={(e) => onChange(e.target.value)}
              required={required}
              isDisabled={disabled}
            />
            <InputRightElement width="4.5rem">{rightElement}</InputRightElement>
          </InputGroup>
        );
      case "editable":
        return (
          <Editable
            placeholder={placeholder}
            value={state}
            defaultValue={value}
            pt={offset}
          >
            <EditablePreview {...rest} />
            <Input
              as={EditableInput}
              focusBorderColor="transparent"
              borderColor="transparent"
              borderRadius={borderRadius}
              pl={offsetLeft}
              type={type}
              value={state}
              placeholder={placeholder}
              defaultValue={value}
              onChange={(e) => onChange(e.target.value)}
              required={required}
              isDisabled={disabled}
              {...rest}
            />
            {button}
          </Editable>
        );
      case "radio":
        return (
          <RadioGroup
            required={required}
            borderRadius={borderRadius}
            focusBorderColor={focusBorderColor}
            isDisabled={disabled}
            defaultValue={value}
            onChange={(e) => onChange(e.target.value)}
            placeholder={placeholder}
          >
            <Stack>
              {options.map((type, i) => (
                <Radio colorScheme="teal" key={i} value={type}>
                  {Number.isInteger(type)
                    ? type
                    : type.charAt(0).toUpperCase() + type.slice(1)}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        );
      case "number":
        return (
          <NumberInput
            size={size}
            focusBorderColor={focusBorderColor}
            type={type}
            placeholder={placeholder}
            defaultValue={value}
            required={required}
            isDisabled={disabled}
            {...rest}
          >
            <NumberInputField
              borderRadius={borderRadius}
              onChange={(e) => onChange(e.target.value)}
            />
            {stepper && (
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            )}
          </NumberInput>
        );
      default:
        return (
          <InputGroup>
            <Input
              size={size}
              focusBorderColor={focusBorderColor}
              borderRadius={borderRadius}
              type={type}
              placeholder={placeholder}
              defaultValue={value}
              onChange={(e) => onChange(e.target.value)}
              required={required}
              isDisabled={disabled}
              {...rest}
            />
            <InputRightElement width="4.5rem">{rightElement}</InputRightElement>
          </InputGroup>
        );
    }
  };
  return (
    <FormControl>
      {!noLabel.includes(type) && <FormLabel> {label}</FormLabel>}
      {inputType()}
      {helper && <FormHelperText>{helper}</FormHelperText>}
    </FormControl>
  );
}

export default InputField;
