import {
  db,
  realtime,
  auth,
  googleProvider,
  storage,
} from "../configs/firebaseConfigs";
import {
  doc,
  collection,
  setDoc,
  updateDoc,
  deleteDoc,
  getDoc,
  onSnapshot,
  getDocs,
  query as queryFirestore,
  orderBy,
  where,
  getCountFromServer,
} from "firebase/firestore";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  fetchSignInMethodsForEmail,
} from "firebase/auth";
import {
  push,
  ref,
  set,
  remove,
  onValue,
  query,
  update,
  serverTimestamp,
  orderByChild,
  equalTo,
} from "firebase/database";
import { uploadBytes, ref as storeRef, getDownloadURL } from "firebase/storage";

export const uploadImage = async (
  file,
  func,
  disabled = () => {},
  loading = () => {}
) => {
  const storageRef = storeRef(storage, `images/${file.name}`);
  const snapshot = await uploadBytes(storageRef, file);
  const url = await getDownloadURL(snapshot.ref);
  func(url);
  disabled(true);
  loading(false);
  return url;
};
export const resetPass = (email) => {
  return sendPasswordResetEmail(auth, email);
};
export const checkAuth = (email) => {
  return fetchSignInMethodsForEmail(auth, email)
    .then((providers) => {
      console.log("Providers for email address " + email + ": ", providers);
      if (providers.length === 1) {
      }
    })
    .catch((error) => {
      console.log(
        "Error fetching providers for email address " + email + ": ",
        error,
      );
    });
};
export const downloadCSV = (data, filename) => {
  // Convert JSON to CSV format
  let heads = Object.keys(data[0]).filter((key) => {
    if (key !== "addon") return key;
  });
  const csv =
    heads.join(",") +
    "\n" +
    data
      .map((item) => {
        return heads
          .map((keys) => {
            if (!item.hasOwnProperty(keys)) return "";
            if (keys === "datentime") return new Date(item[keys]).toString();
            else if (typeof item[keys] === "object")
              return JSON.stringify(item[keys]);
            return item[keys];
          })
          .join(",");
      })
      .join("\n");
  // return 0
  // Create a new Blob object with the CSV data
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

  // Create a temporary anchor element to trigger the download
  const link = document.createElement("a");
  if (link.download !== undefined) {
    // Use link.download if available on browser
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    // If link.download is not available, open the data in a new window
    window.open("data:text/csv;charset=utf-8," + escape(csv));
  }
};

export const deleteDataField = async (collection, document, field) => {
  // console.log(collection,document)
  // await updateDoc(doc(db, collection, document), {
  //   field: deleteField()
  // })
};

export const deleteData = async (collection, document) => {
  await deleteDoc(doc(db, collection, document));
};

export const deleteDataRealtime = async (collection) => {
  await remove(ref(realtime, collection));
};
export const countDocuments = async (col, cond = null) => {
  let reference;
  if (cond) reference = queryFirestore(collection(db, col), where(...cond));
  else reference = queryFirestore(collection(db, col));
  const docSnap = await getCountFromServer(reference);
  return docSnap.data().count;
};
function convertSub(sub) {
  let res = [];
  Object.keys(sub).forEach((key) => {
    if (sub[key].hasOwnProperty("sub")) sub[key].sub = convertSub(sub[key].sub);
    res.unshift(sub[key]);
  });
  // console.log(res)
  return res;
}

function jamToArray(snapshot) {
  const returnArr = [];

  snapshot.forEach((childSnapshot) => {
    const item = childSnapshot.val();
    console.log(item);
    if (!item.hasOwnProperty("id")) return;
    returnArr.unshift(item);
  });

  return returnArr;
}

export const getMenuProducts = async (path, collection, document) => {
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("category/path"),
    equalTo(path),
  );
  let data = {};
  await onValue(reference, (snapshot) => {
    data = jamToArray(snapshot);
    // console.log(data)
    if (data) {
      return data;
    }
  });
};
//REALTIME DATABASE

export const sendDataRealtime = async (collection, document, data) => {
  console.log(collection, document, data);
  if (!Array.isArray(data)) {
    data.datentime = serverTimestamp();
  }
  try {
    await set(ref(realtime, collection + "/" + document), data);
    console.log("Data sent successfully.");
    return true;
  } catch (error) {
    console.error("Error sending data:", error);
    return false;
  }
};

export const addDataRealtime = async (collection, data) => {
  try {
    await push(ref(realtime, collection), data);
    console.log("Data added successfully.");
    return true;
  } catch (error) {
    console.error("Error adding data:", error);
    return false;
  }
};

export const updateDataRealtime = async (collection, data) => {

  try {
    await update(ref(realtime, collection), data);
    console.log("Data updated successfully.");
    return true;
  } catch (error) {
    console.error("Error updating data:", error);
    return false;
  }
};
export const getDataRealtime = (
  collection,
  document,
  func,
  load = () => false,
) => {
  return new Promise((resolve, reject) => {
    const reference = query(
      ref(realtime, `${collection}/${document}`),
      orderByChild("datentime"),
    );

    const unsubscribe = onValue(
      reference,
      (snapshot) => {
        const data = snapshot.val();

        if (data) {
          console.log(data);
          func(data);
          load(false);
          resolve(data);
        } else {
          reject(new Error("No data available"));
        }
      },
      (error) => {
        reject(error);
      },
    );

    // return an unsubscribe function to stop listening for changes
    return unsubscribe;
  });
};

const getCategories = (categoryList, prefix) => {
  let resArray = [];
  categoryList.forEach((element) => {
    element.name = prefix + element.name;
    if (element.hasOwnProperty("sub")) {
      resArray.push(...getCategories(element.sub, element.name + " > "));
    } else resArray.push(element);
  });
  return resArray;
};

export const getCatDataRealtimeInArray = async (
  collection,
  document,
  func,
  load = (e) => {
    return 0;
  },
) => {
  console.log(document);
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("datentime"),
  );
  let data = {};
  await onValue(reference, (snapshot) => {
    data = jamToArray(snapshot);
    func(getCategories(data, ""));
    load(false);
  });
  return data;
};
export const getDataRealtimeInArray = async (
  collection,
  document = "",
  func,
  load = (e) => {
    return 0;
  },
) => {
  console.log(collection + "/" + document);
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("datentime"),
  );
  let data = {};
  return await onValue(reference, (snapshot) => {
    data = jamToArray(snapshot);
    console.log(data);
    func(data);
    load(false);
  });
};
export const getMenuDataRealtimeInArray = async (
  collection,
  document,
  path,
  func,
  load = (e) => {
    return 0;
  },
) => {
  // console.log(collection,document,path)
  const reference = query(
    ref(realtime, collection + "/" + document),
    orderByChild("category/path"),
    equalTo(path),
  );
  let data = {};
  await onValue(reference, (snapshot) => {
    data = jamToArray(snapshot);
    console.log(data);
    if (data) {
      func(data);
      load(false);
    }
  });
};

//FIRESTORE DATABASE
export const sendData = async (collection, document, data) => {
  try {
    data.datentime = Date.now();
    await setDoc(doc(db, collection, document), data);
    console.log("Data sent successfully!");
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

export const updateData = async (collection, document, data) => {
  try {
    data.datentime = Date.now();
    await updateDoc(doc(db, collection, document), data);
    console.log("Data updated successfully!");
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export const getCollectionRealtime = async (
  col,
  func,
  load = () => false,
  cond = null,
  signal = null,
) => {
  let data = [];
  let reference;

  try {
    if (cond === null) {
      reference = query(collection(db, col), orderBy("datentime", "desc"));
    } else {
      reference = query(
        collection(db, col),
        where(...cond),
        orderBy("datentime", "desc"),
      );
    }

    const querySnapshot = await getDocs(reference);

    querySnapshot.forEach((doc) => {
      if (doc.data().id !== currUser.id) {
        data.push(doc.data());
      }
    });

    func(data);
    load(false);
    console.log(data);

    return data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    // throw new Error('Error fetching data')
  }
};

export const getCollectionRealtimeRealtime = async (
  col,
  func,
  load = () => false,
  cond = null,
  signal = null,
) => {
  let data = [];
  let reference;

  try {
    if (cond === null) {
      reference = query(collection(db, col), orderBy("datentime", "desc"));
    } else {
      reference = query(
        collection(db, col),
        where(...cond),
        orderBy("datentime", "desc"),
      );
    }

    await onSnapshot(reference, (querySnapshot) => {
      data = [];
      querySnapshot.forEach((doc) => {
        if (doc.data().id !== currUser.id) {
          data.push(doc.data());
        }
      });

      func(data);
      load(false);
      return true;
    });
  } catch (error) {
    console.error("Error fetching data: ", error);
    return false;
    // throw new Error('Error fetching data')
  }
};

export const getDocument = async (collection, document) => {
  const docSnap = await getDoc(doc(db, collection, document));
  return docSnap.data();
};

export const getDocumentRealtime = async (collection, document, func) => {
  await onSnapshot(doc(db, collection, document), (doc) => {
    func(doc.data());
  });
};

export var currUser =
  typeof window !== "undefined"
    ? JSON.parse(localStorage.getItem("user"))
    : global.user;
export const setCurrentShop = (shop) => {
  global.currShop = shop;
  return null;
};

export const loginGoogleUser = async () => {
  console.log(googleProvider);
  return await signInWithPopup(auth, googleProvider);
};
export const loginUser = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );
    return userCredential;
  } catch (error) {
    // Handle the error here
    const errorCode = error.code;
    const errorMessage = error.message;
    console.log(errorCode, errorMessage);
    throw new Error(errorMessage);
  }
};

export const logoutUser = async () => {
  await signOut(auth);
  localStorage.clear();
};

export const createLogin = async (
  name,
  email,
  password,
  phone,
  shop,
  status,
  description,
  role,
) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      let user = userCredential.user;
      console.log(
        sendData("users", user.uid, {
          id: user.uid,
          name: name,
          email: email,
          shop: shop,
          phone: phone,
          status: status,
          description: description,
          role: role,
        }),
      );
    })
    .catch((error) => {
      console.log(error.message);
      return error.message;
    });
};

export const getCurrentUser = async (
  func = (e) => {
    return 0;
  },
  setError = (e) => {
    return 0;
  },
) => {
  await onAuthStateChanged(auth, (user) => {
    if (user) {
      getDocument("users", user.uid).then(
        function (value) {
          value.currShop = value.role == "Author" ? value.shop[0] : value.shop;
          func(value);
          currUser = value;
        },
        function (error) {
          setError(error);
        },
      );
      return user.email;
    } else {
      func(null);
      return "User not found";
    }
  });
};
