import { useState, useRef } from "react";
import {
  Flex,
  Text,
  Grid,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  useColorModeValue,
} from "@chakra-ui/react";
import InputField from "components/Forms/InputField";
import * as functionLib from "utils/functionLib";

import * as firebaseLib from "utils/FirebaseUtils";

import { useHistory } from "react-router";
export default function ContactForm() {
  const navigate = useHistory();
  const formRef = useRef(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const cardColor = useColorModeValue("white", "gray.700");
  const handleSubmit = (e) => {
    e.preventDefault();

    formRef.current.reset();
  };
  return (
    <Flex flexDirection="column">
      <Grid
        templateColumns={{ md: "1fr" }}
        templateRows={{ md: "1fr auto", lg: "1fr" }}
        gap="24px"
      >
        <Card
          variant="elevated"
          bg={cardColor}
          boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)"
          borderRadius="15px"
        >
          <CardBody>
            <form
              style={{ width: "100%" }}
              ref={formRef}
              onSubmit={(e) => handleSubmit(e)}
              id="2040bbf1-856a-4ef4-9e41-6703fcf63e03"
            >
              <Grid templateColumns="1fr 1fr" gap="1rem" width="100%">
                <InputField
                  type="text"
                  label="Name"
                  value=""
                  placeholder="Name"
                  state={name}
                  onChange={setName}
                  disabled
                  required
                />
                <InputField
                  type="tel"
                  label="Phone Number"
                  value=""
                  placeholder=""
                  state={phoneNumber}
                  onChange={setPhoneNumber}
                  disabled
                  required
                />
              </Grid>
            </form>
          </CardBody>
          <CardFooter>
            <Button
              bg="teal.400"
              type="submit"
              form="2040bbf1-856a-4ef4-9e41-6703fcf63e03"
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Grid>
    </Flex>
  );
}
