import { DocumentIcon, RocketIcon, PersonIcon } from "components/Icons/Icons";
import Profile from "views/Dashboard/Profile";
import SignIn from "views/Auth/SignIn.js";
import SignUp from "views/Auth/SignUp.js";
import ContactDetails from "components/Tables/ContactDetails.js";
import ContactForm from "components/Forms/ContactDetails.js";
import Photos from "components/CardScreens/Photos.js";
import AddImage from "components/Forms/AddImage.js";
var dashRoutes = [
  {
    path: "/contactdetails",
    name: "Contact Details",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: ContactDetails,
    layout: "/admin",
    redirect: false,
  },
  {
    path: "/contactform",
    name: "Contact Entries",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: ContactForm,
    layout: "/admin",
    redirect: true,
  },
  {
    path: "/photos",
    name: "Photos",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: Photos,
    layout: "/admin",
    redirect: false,
  },
  {
    path: "/addimage",
    name: "Add Image",
    rtlName: "",
    icon: <DocumentIcon color="inherit" />,
    component: AddImage,
    layout: "/admin",
    redirect: true,
  },
  {
    name: "ACCOUNT PAGES",
    category: "account",
    rtlName: "صفحات",
    state: "pageCollapse",
    views: [
      {
        path: "/signin",
        name: "Sign In",
        rtlName: "لوحة القيادة",
        icon: <DocumentIcon color="inherit" />,
        component: SignIn,
        layout: "/auth",
        redirect: true,
      },
      {
        path: "/signup",
        name: "Sign Up",
        rtlName: "لوحة القيادة",
        icon: <RocketIcon color="inherit" />,
        secondaryNavbar: true,
        component: SignUp,
        layout: "/auth",
        redirect: true,
      },
    ],
  },
];

export default dashRoutes;
