import React, { useState, useEffect } from "react";
import {
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Flex,
  Card,
  CardBody,
  Progress,
} from "@chakra-ui/react";

import { getDataRealtimeInArray } from "utils/FirebaseUtils";
import * as functionLib from "utils/functionLib";

import * as firebaseLib from "utils/FirebaseUtils";

import TableRow from "components/Tables/TableRow";
import { useHistory } from "react-router";
import { TbDatabaseOff } from "react-icons/tb";

const MemoizedTableRow = React.memo(TableRow);
export default function ContactDetails() {
  const navigate = useHistory();
  const cardColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.700", "white");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const headers = {
    Name: "name",
    "Phone Number": "phone",
    Message: "message",
    "Created At": "datentime",
  };
  useEffect(() => {
    getDataRealtimeInArray("Contact", "", setData, setLoading);
  }, []);
  return (
    <Flex flexDirection="column">
      {loading ? (
        <Progress colorScheme="teal" size="xs" isIndeterminate />
      ) : (
        <Card
          variant="elevated"
          bg={cardColor}
          cursor="pointer"
          boxShadow="0px 5px 10px rgba(0, 0, 0, 0.1)"
          borderRadius="15px"
        >
          <CardBody overflowX="scroll">
            <Table variant="simple" color={textColor}>
              {data.length > 0 ? (
                <>
                  <Thead>
                    <Tr my=".8rem" color="gray.400">
                      {Object.keys(headers).map((caption, idx) => {
                        return (
                          <Th color="gray.400" key={idx}>
                            <Text fontSize="md">{caption}</Text>
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {data.map((row, i) => {
                      return (
                        <MemoizedTableRow
                          key={i}
                          keys={Object.values(headers)}
                          data={row}
                          col="Contact"
                        />
                      );
                    })}
                  </Tbody>
                </>
              ) : (
                <Flex
                  h="40vh"
                  w="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    sx={{
                      color: "gray.400",
                      fontSize: "4xl",
                      textAlign: "center",
                    }}
                  >
                    <TbDatabaseOff />
                  </Text>
                  <Text sx={{ color: "gray.400", fontSize: "xl" }}>
                    NO ENTRIES
                  </Text>
                </Flex>
              )}
            </Table>
          </CardBody>
        </Card>
      )}
    </Flex>
  );
}
