import {
  Box,
  CloseButton,
  Flex,
  Text,
  CardHeader,
  CardFooter,
  Card,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { deleteDataRealtime, updateDataRealtime } from "utils/FirebaseUtils";
import EditableText from "./EditableText";
import { useState, useEffect } from "react";

function ImageCard({
  id = "",
  project_id = "",
  data = {},
  path = "",
  datentime = "",
}) {
  const [text, setText] = useState(data.pos);
  const toast = useToast();
  const user = useSelector((state) => state.user);
  const navigate = useHistory();

  useEffect(() => {
    setText(data.pos);
  }, [data.pos]); // Update text state when data.pos changes

  const cardColor = useColorModeValue("white", "gray.700");
  const date = new Date(datentime);
  const formattedDate = date.toLocaleString();
  const handleDelete = () => {
    deleteDataRealtime(path).then(() => {
      toast({
        position: "bottom-right",
        title: "Image Deleted",
        variant: "subtle",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    });
  };

  return (
    <Card
      variant="elevated"
      bg={cardColor}
      cursor="pointer"
      boxShadow="0px 3.5px 5.5px rgba(0, 0, 0, 0.02)"
      borderRadius="15px"
    >
      <CardHeader py="1rem">
        <Flex spacing="4">
          <Box borderRadius="10px" overflow="hidden" width="80%" height="80%">
            <img
              src={data.link}
              alt={data.id}
              style={{
                borderRadius: "10px",
                objectFit: "cover",
                width: "100%",
                height: "100%",
              }}
            />
          </Box>

          <CloseButton
            onClick={() => handleDelete()}
            color="red.400"
            ml={"auto"}
            size="lg"
          />
        </Flex>
      </CardHeader>
      <CardFooter mt="auto">
        <EditableText
          text={text}
          setText={setText}
          onSubmit={() => {
            updateDataRealtime(`Gallery/${data.id}`, { pos: parseInt(text) }).then(() => {
              toast({
                position: "bottom-right",
                title: "Image Uploaded",
                variant: "subtle",
                status: "success",
                duration: 4000,
                isClosable: true,
              });
            });
          }}
        />
        {/* <Text
          ml="auto"
          align="right"
          p="0.5rem"
          fontSize="small"
          color="gray.400"
          onClick={() =>
            navigate.replace(`/admin/elements/${project_id}/${id}`)
          }
        >
          {data.id}
        </Text> */}
      </CardFooter>
    </Card>
  );
}

export default ImageCard;
