import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import { FaCube, FaPenFancy } from "react-icons/fa";
import { IoDocumentsSharp } from "react-icons/io5";
import Header from "./components/Header";
import PlatformSettings from "./components/PlatformSettings";
import ProfileInformation from "./components/ProfileInformation";
import { useSelector } from "react-redux";

function Profile() {
  // Chakra color mode
  const user = useSelector((state) => state.user);
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)",
  );

  return (
    <Flex direction="column">
      <Header
        backgroundHeader="https://firebasestorage.googleapis.com/v0/b/accidental-doctor.appspot.com/o/temp%2FProfileBackground.png?alt=media&token=769a4c7c-4ad2-42a9-a22a-660e5639db5a"
        backgroundProfile={bgProfile}
        avatarImage="https://firebasestorage.googleapis.com/v0/b/accidental-doctor.appspot.com/o/temp%2Favatar1.png?alt=media&token=a71af519-0b6f-4099-9dbe-0bbd66f2651a"
        name={"Esthera Jackson"}
        email={user.email}
        tabs={[
          {
            name: "OVERVIEW",
            icon: <FaCube w="100%" h="100%" />,
          },
          {
            name: "TEAMS",
            icon: <IoDocumentsSharp w="100%" h="100%" />,
          },
          {
            name: "PROJECTS",
            icon: <FaPenFancy w="100%" h="100%" />,
          },
        ]}
      />
      <Grid templateColumns={{ sm: "1fr", xl: "repeat(3, 1fr)" }} gap="22px">
        <PlatformSettings
          title={"Platform Settings"}
          subtitle1={"ACCOUNT"}
          subtitle2={"APPLICATION"}
        />
        <ProfileInformation
          title={"Profile Information"}
          description={
            "Hi, IÃƒÂ¢Ã¢â€šÂ¬Ã¢â€žÂ¢m Esthera Jackson, Decisions: If you canÃƒÂ¢Ã¢â€šÂ¬Ã¢â€žÂ¢t decide, the answer is no. If two equally difficult paths, choose the one more painful in the short term (pain avoidance is creating an illusion of equality)."
          }
          name={"Esthera Jackson"}
          mobile={"(44) 123 1234 123"}
          email={"esthera@simmmple.com"}
          location={"United States"}
        />
        {/* <Conversations title={"Conversations"} /> */}
      </Grid>
    </Flex>
  );
}

export default Profile;
