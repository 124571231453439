import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  Flex,
  Text,
} from "@chakra-ui/react";
import PropTypes from "prop-types";

function ReusableSlider({ data, state, defaultValue, onChange }) {
  const type = typeof data;
  if (type === "object" && !data.hasOwnProperty("min")) {
    data = Object.values(data);
  }
  const min = data.hasOwnProperty("min") ? data.min : 0;
  const max = data.hasOwnProperty("max") ? data.max : data.length - 1;
  const step = 1;

  console.log(state, data, max, min);
  function handleChange(value) {
    onChange(
      type === "object"
        ? data.hasOwnProperty("min")
          ? value
          : data[value]
        : data[value],
    );
  }

  return (
    <Flex mb={4}>
      <Slider
        min={min}
        max={max}
        step={step}
        defaultValue={
          type === "object" && !data.hasOwnProperty("min")
            ? data.indexOf(defaultValue)
            : defaultValue
        }
        onChange={handleChange}
        colorScheme="teal"
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <Text minW="4rem" casing={"uppercase"} align={"center"}>
        {typeof state === "undefined" || state === "" ? defaultValue : state}
      </Text>
    </Flex>
  );
}

ReusableSlider.propTypes = {
  data: PropTypes.array.isRequired,
  defaultValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ReusableSlider;
