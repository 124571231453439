import {
  Button,
  Td,
  Text,
  Tr,
  useColorModeValue,
  Tooltip,
  IconButton,
  CloseButton,
  useToast,
} from "@chakra-ui/react";
import { RxPencil1 } from "react-icons/rx";
import { motion, useMotionValue } from "framer-motion";
import { useEffect } from "react";
import { deleteDataRealtime } from "utils/FirebaseUtils";

function isImageLink(link) {
  // Check if the input is a string
  if (typeof link !== "string") {
    return false; // Return false for non-string inputs
  }

  // Define regex to match image file extensions
  const regex = /\.(jpg|jpeg|png|gif)$/i;

  // Check if the link starts with the specified URL or matches the regex
  return (
    link.startsWith("https://firebasestorage.googleapis.com/") ||
    regex.test(link)
  );
}

function TableRow({ data, keys, col }) {
  const toast = useToast();
  const scale = useMotionValue(1);
  const textColor = useColorModeValue("gray.700", "white");
  useEffect(() => {
    if (data.hasOwnProperty("datentime")) {
      const date = new Date(data.datentime);
      data["datentime"] = date.toLocaleString();
    }
    return () => {};
  }, []);
  const dataToDisplayComplete = (key) =>
    key === "datentime"
      ? formattedDate(data[key])
      : data.hasOwnProperty(key)
        ? data[key]
        : "";
  const dataToDisplay = (key) =>
    key === "datentime"
      ? formattedDate(data[key])
      : key === "msg"
        ? data[key].slice(0, 15) + "..."
        : data.hasOwnProperty(key)
          ? data[key]
          : "";
  const formattedDate = (date) => {
    const formattedDate = new Date(date);
    return formattedDate.toLocaleString();
  };
  const handleDelete = () => {
    deleteDataRealtime(col + "/" + data.id).then(() => {
      toast({
        position: "bottom-right",
        title: "Entry Deleted",
        variant: "subtle",
        status: "error",
        duration: 4000,
        isClosable: true,
      });
    });
  };
  return (
    <Tr>
      {keys.map((key, i) => {
        console.log(key, data[key]);
        return (
          <Td key={i}>
            {data.hasOwnProperty(key) && isImageLink(data[key]) ? (
              <motion.img
                src={data[key]}
                alt=""
                style={{
                  scale,
                  maxHeight: "1.6rem",
                  borderRadius: "5px",
                }}
                whileHover={{ scale: 3 }}
                whileTap={{ scale: 0.9 }}
              />
            ) : (
              <Tooltip
                bg="teal.400"
                placement="bottom"
                label={dataToDisplayComplete(key)}
              >
                <Text my="auto" fontSize="md" color={textColor}>
                  {dataToDisplay(key)}
                </Text>
              </Tooltip>
            )}
          </Td>
        );
      })}
      <Td>
        <Tooltip bg="red.400" placement="bottom" label="DELETE">
          <CloseButton
            onClick={() => handleDelete()}
            color="red.400"
            size="sm"
            my="auto"
          />
        </Tooltip>
      </Td>
    </Tr>
  );
}

export default TableRow;
