import React, { useState } from "react";
import {
  Editable,
  EditablePreview,
  EditableInput,
  Button,
} from "@chakra-ui/react";

const EditableText = ({ initialValue, text, setText, onSubmit }) => {
  return (
    <Editable
    colorScheme="teal" 
      display="flex"
      w="100%"
      defaultValue={initialValue}
      value={text}
      onChange={setText}
    >
      <EditablePreview
        mr="auto"
        align="left"
        p="0.5rem"
        fontSize="md"
        color="gray.100"
      />
      <EditableInput
      type="number"
        mr="auto"
        align="left"
        p="0.5rem"
        fontSize="md"
        color="gray.200"
      />
      <Button onClick={onSubmit} size="md" ml="1rem">
        Save
      </Button>
    </Editable>
  );
};

export default EditableText;
