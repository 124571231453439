import { CardHeader as NativeCardHeader } from "@chakra-ui/react";
function CardHeader(props) {
  const { children, ...rest } = props;
  // Pass the computed styles into the `__css` prop
  return (
    <NativeCardHeader pt="1rem" {...rest}>
      {children}
    </NativeCardHeader>
  );
}

export default CardHeader;
